import React from 'react'

import Seo from '../components/seo'

const PricingPage = () => (
  <>
    <Seo title="Pricing" />
    <h1>Pricing</h1>
    <p>Welcome to the pricing page.</p>
  </>
)

export default PricingPage
